import React from 'react';
import IconOne from '../../assets/images/icon/1.png';
import IconTwo from '../../assets/images/icon/2.png';
import IconThree from '../../assets/images/icon/3.png';
import IconFour from '../../assets/images/icon/4.png';
import IconFive from '../../assets/images/icon/5.png';
import IconSix from '../../assets/images/icon/6.png';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">Metaverse</h3>
                            {/* <h3 className="appie-title">
                                Designed with <br /> The applicant in mind.
                            </h3>
                            <p>The full monty spiffing good time no biggie cack grub fantastic. </p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" />
                            </div>
                            <h4 className="appie-title">
                                <a
                                    href="https://metaverse.digitalcity.id/smartlab/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Smart-Lab
                                </a>
                            </h4>
                            {/* <p>Mucker plastered bugger all mate morish are.</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                            </div>
                            <h4 className="appie-title">
                                <a
                                    href="https://metaverse.digitalcity.id/prasetiyamulya/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Prasetiya Mulya
                                </a>
                            </h4>
                            {/* <p>Mucker plastered bugger all mate morish are.</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <img src={IconThree} alt="" />
                            </div>
                            <h4 className="appie-title">
                                <a
                                    href="https://metaverse.digitalcity.id/politeknik-meta/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Politeknik Meta Cikarang
                                </a>
                            </h4>
                            {/* <p>Mucker plastered bugger all mate morish are.</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img style={{ height: '60px' }} src={IconFour} alt="" />
                            </div>
                            <h4 className="appie-title">
                                <a
                                    href="https://metaverse.digitalcity.id/pesantrenarrahmah/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Pesantren Modern Ar Rahmah
                                </a>
                            </h4>
                            {/* <p>Mucker plastered bugger all mate morish are.</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconFive} alt="" />
                            </div>
                            <h4 className="appie-title">
                                <a
                                    href="https://metaverse.digitalcity.id/kidilab/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Digipreneur Lab
                                </a>
                            </h4>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconSix} alt="" />
                            </div>
                            <h4 className="appie-title">
                                <a
                                    href="https://mooc-bpsdm.kalteng.go.id/metaverse/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    MOOC-BPSDM Kalteng
                                </a>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
